$(document).ready(function () {

    // Mobile Safari in standalone mode
    if (("standalone" in window.navigator) && window.navigator.standalone) {

        //  If you want to prevent remote links in standalone web apps opening Mobile Safari, change 'remotes' to true
        var noddy, remotes = false;

        document.addEventListener('click', function (event) {

            noddy = event.target;

            // Bubble up until we hit link or top HTML element. Warning: BODY element is not compulsory so better to stop on HTML
            while (noddy.nodeName !== "A" && noddy.nodeName !== "HTML") {
                noddy = noddy.parentNode;
            }

            if ('href' in noddy && noddy.href.indexOf('http') !== -1 && (noddy.href.indexOf(document.location.host) !== -1 || remotes)) {
                event.preventDefault();
                document.location.href = noddy.href;
            }

        }, false);
    }


    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        cache: false
    });

    window.sr = new scrollReveal();

    // Colorbox
    $('a.gallery').colorbox({
        rel: 'gal',
        maxHeight: '90%',
        maxWidth: '90%'

    });

    var mySwiper = new Swiper('.swiper-container', {
        // Optional parameters
        //direction: 'vertical',
        autoplay: 5000,
        speed: 600,
        loop: true,
        parallax: true,

        //// If we need pagination
        //pagination: '.swiper-pagination',
        //
        //// Navigation arrows
        //nextButton: '.swiper-button-next',
        //prevButton: '.swiper-button-prev',
        //
        //// And if we need scrollbar
        //scrollbar: '.swiper-scrollbar',
    })


    //$('#blueimp-gallery').data();
    //var gallery = $('#blueimp-gallery').data('gallery');
    $('#carousel-header').carousel({
        interval: 7000
    })
    // delay while loading
    $(".carousel-caption").delay(400).fadeIn();
    $(".carousel-caption-2").delay(600).fadeIn();

    $('#carousel-header').on('slide.bs.carousel', function () {
        $(".carousel-caption").fadeOut();
        $(".carousel-caption-2").delay(200).fadeOut();
    })
    $('#carousel-header').on('slid.bs.carousel', function () {
        $(".carousel-caption").fadeIn();
        $(".carousel-caption-2").delay(200).fadeIn();
    })

    $('.matchheight').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    $(window).scroll(function () {
        if ($(document).scrollTop() > 26) {
            $('.navtop').addClass('shrink');
            $('nav').addClass('shrink');
        } else {
            $('.navtop').removeClass('shrink');
            $('nav').removeClass('shrink');
        }
    });

    // demoform
    $("#demoformSubmit").on("click", function (e) {
        e.preventDefault();
        var data = $('#demoform').serializeArray();
        //console.log(data);
        var loadUrl = $('#demoform').attr('action');

        $('#demoformSubmit').prop("disabled", true);
        $('#sendState').html('<i class="fa fa-circle-o-notch fa-spin"></i>');

        $.ajax({
            url: loadUrl,
            type: "POST",
            cache: false,
            data: $('#demoform').serializeArray(),

            success: function (response) {
                $('#collapseDemoForm').html('<div class="gap gap-md"></div><h3>' + response.header + '</h3><p>' + response.message + '</p>');
            },

            error: function (data) {
                var errors = JSON && JSON.parse(data.responseText) || $.parseJSON(data.responseText);//$.parseJSON(data.responseText);

                $.each(errors, function (index, value) {

                    //$('input[name="'+index+'"]').css('border-color', 'red');

                    if ($('input[name="' + index + '"]').next('span').length)
                        $('#' + index).text(value);
                    else
                        $('input[name="' + index + '"]').after("<span id='" + index + "' class='help-block small danger'>" + value + "</span>");
                });
                $('#demoformSubmit').prop("disabled", false);
                $('#sendState').html('');

            }

        });
    });


    // demoform
    $("#newsletterSubscribe").on("click", function (e) {
        e.preventDefault();
        var data = $('#newsletterform').serializeArray();
        var loadUrl = $('#newsletterform').attr('action');


        $.ajax({
            url: loadUrl,
            type: "POST",
            cache: false,
            data: $('#newsletterform').serializeArray(),

            success: function (response) {
                //console.log(response);
                $('.subscribe_button').html('<i class="fa fa-circle-o-notch fa-spin fa-2x"></i>');
                $('#subscribe_newsletter').html('<h3>' + response.header + '</h3><p>' + response.message + '</p>');
            },

            error: function (data) {
                var errors = JSON && JSON.parse(data.responseText) || $.parseJSON(data.responseText); //$.parseJSON(data.responseText);

                $.each(errors, function (index, value) {

                    //$('input[name="'+index+'"]').css('border-color', 'red');

                    if ($('input[name="' + index + '"]').next('span').length)
                        $('#' + index).text(value);
                    else
                        $('input[name="' + index + '"]').after("<span id='" + index + "' class='help-block small danger'>" + value + "</span>");
                });
            }

        });
    });


});